import Hero from "../organisms/Hero";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import bg from "@/images/bgs/promotions-bg.webp";
import PromotionCard from "../molecules/PromotionCard";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import leftArrow from "@/images/icons/left-arrow.svg";
import { getCompleteCollection } from "@/utils/helpers";

const settings = {
  autoplay: {
    delay: 5000,
    disableOnInteraction: true,
  },
  slidesPerView: 1,
  spaceBetween: 12,
  breakpoints: {
    1024: {
      slidesPerView: 2,
      spaceBetween: 12,
    },
    1280: {
      slidesPerView: 2,
      spaceBetween: 64
    }
  },
  speed: 1000,
};

const PromotionsCarousel = ({
  promotions = [],
  hero = {},
  isNavigation = false,
  styles = {},
}) => {
  const { t } = useTranslation("common");
  const completePromotions = getCompleteCollection({
    type: "Promoción",
    list: promotions,
    requiredFields: ["name", "description", "link", "slug", "thumbnail"],
  })
  return (
    <section
      className="base-bg pb-10 relative"
    >
      <div className="absolute w-full h-full">
        <Image className="w-full h-full object-cover" src={bg} alt="promotions-background" />
      </div>
      <Hero
        title={{
          text: hero?.title?.text || t("common:promotions_section.title"),
          className: "hero-title md:text-5xl text-white md:text-left",
        }}
        text={{
          text: hero?.text?.text || t("common:promotions_section.subtitle"),
          className:
            "section-subtitle text-white uppercase text-center md:text-left text-[14px] lg:text-[16px]",
        }}
        link={{
          text: hero?.link?.text || t("common:promotions_section.link"),
          className: "hero-link self-center",
          href: { pathname: t("routes:/deals") },
        }}
        paragraphs={hero?.paragraphs || false}
        styles={{
          titleContainer:
            "col-start-2 col-span-10 md:col-start-2 md:col-span-10 lg:col-start-2 lg:col-span-7 order-2",
          linkContainer:
            "col-start-2 col-span-8 lg:col-span-3 order-4 lg:order-3 lg:text-right md:flex lg:justify-end hidden",
          paragraphsContainer: "col-start-2 col-span-8 order-3 lg:order-4",
          heroContainer: "base-container pb-[48px] pt-[20px] relative",
        }}
      />
      <div className="relative">
        <div className="base-container px-4">
          <Swiper
            {...settings}
            pagination={{ clickable: true }}
            navigation={{
              nextEl: ".nav-button-next",
              prevEl: ".nav-button-prev",
            }}
            modules={[Pagination, Autoplay, Navigation]}
            style={{
              "--swiper-theme-color": styles.themeColor || "#007aff",
            }}
          >
            {Array.isArray(completePromotions) &&
              completePromotions?.map((promotion, index) => (
                <SwiperSlide key={promotion.id}>
                  <PromotionCard promotion={promotion} />
                </SwiperSlide>
              ))}
          </Swiper>
        </div>
        <div className="nav-button-prev lg:hidden w-12 h-12 md:-mt-12 bg-main-grey/50 absolute top-1/2 transform -translate-y-1/2 z-30 flex justify-center">
          <Image src={leftArrow} alt="left arrow" />
        </div>
        <div className="nav-button-next lg:hidden w-12 h-12 md:-mt-12 bg-main-grey/50 absolute top-1/2 transform -translate-y-1/2 bottom-6 right-0 z-30 flex justify-center">
          <Image
            className="transform rotate-180"
            src={leftArrow}
            alt="left arrow"
          />
        </div>
      </div>
      <Hero
        link={{
          text: hero?.link?.text || t("common:promotions_section.link"),
          className: "hero-link text-center block",
          href: { pathname: t("routes:/deals") },
        }}
        paragraphs={hero?.paragraphs || false}
        styles={{
          heroContainer: "base-container pt-10",
          linkContainer: "col-start-2 col-span-10 md:hidden",
        }}
      />
    </section>
  );
};

export default PromotionsCarousel;
