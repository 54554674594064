import Title from "../atoms/Title";
import Image from "next/image";
import Text from "../atoms/Text";
import Link from "next/link";

const ParnassusRewards = ({ t }) => {
    const rewards = t("home:parnassusRewards.rewards", { returnObjects: true })
    return (
        <section className="bg-main-blue-light lg:mt-20 mt-10">
            <div className=" base-container base-grid">
                <div className="col-span-12 lg:col-span-10 lg:col-start-2 ">
                    <Title tag="h2" className="section-title block lg:pt-12 pt-10 normal-case text-center lg:text-left tracking-normal md:text-5xl text-3xl lg:pb-6 mx-2 md:mx-0">
                        {t("home:parnassusRewards.title")}
                    </Title>
                    <div className="lg:flex grid grid-cols-2">
                        {
                            rewards?.map((reward, key) => {
                                return (
                                    <div className="lg:mr-[3.75vw] mr-0 lg:px-0 px-4 pt-8 lg:pt-0" key={key}>
                                        <div className="flex flex-col items-center min-w-[80px] ">
                                            <Image
                                                src={reward?.icon?.src}
                                                width={reward?.icon?.width}
                                                height={reward?.icon?.height}
                                                alt={reward?.icon?.alt}
                                            />
                                            <Text className="text-center font-title text-main-blue lg:pt-2.5 pt-3.5">
                                                {reward?.primaryText}
                                            </Text>
                                            {
                                                reward?.secondaryText &&
                                                <Text className="text-center font-text text-main-grey text-sm pt-2">
                                                    {reward?.secondaryText}
                                                </Text>
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="flex items-center justify-center pt-8 pb-14">
                        <Link
                            className="hero-link px-11  uppercase "
                            href={t("home:parnassusRewards.ctaLink")}
                            target="_blank"
                        >
                            {t("home:parnassusRewards.ctaText")}
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ParnassusRewards;